/* Make the navbar fixed at the top */
.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

/* Style for the refresh buttons */
.navbar .btn-light {
  display: flex;
  align-items: center;
  justify-content: center;
  /* Maintain equal dimensions */
  width: 40px;
  height: 40px;
  padding: 0; /* Ensure no extra padding */
}

/* Change background color on hover */
.navbar .btn-light:hover {
  background-color: #e2e6ea;
}

/* Adjust icon color for primary navbar */
.navbar .btn-light .bi-arrow-clockwise {
  margin: 0;
  padding: 0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem; /* Ensure the font size is consistent */
  color: #e9c396; /* Set the reload icon color */
}

/* Adjust the navbar-toggler to customize hamburger icon */
.navbar-toggler {
  width: 30px;
  height: 24px;
  padding: 0;
  border: none;
  background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
}

/* Custom hamburger bars */
.navbar-toggler .bar {
  width: 100%;
  height: 2px;
  background-color: #ffffff; /* White bars */
  transition: all 0.3s ease;
  position: absolute;
  left: 0;
}

/* Positioning each bar */
.navbar-toggler .bar1 {
  top: 0;
}

.navbar-toggler .bar2 {
  top: 50%;
  transform: translateY(-50%);
}

.navbar-toggler .bar3 {
  bottom: 0;
}

/* Transformations when navbar is expanded (to form 'X') */
.navbar-toggler.collapsed .bar1 {
  transform: rotate(0) translate(0, 0);
}

.navbar-toggler:not(.collapsed) .bar1 {
  transform: rotate(45deg) translate(10px, 5px);
}

.navbar-toggler:not(.collapsed) .bar2 {
  opacity: 0;
}

.navbar-toggler:not(.collapsed) .bar3 {
  transform: rotate(-45deg) translate(10px, -5px);
}

/* Spinner animation */
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Apply spinning animation when refreshing */
.navbar .btn-light.refreshing .bi-arrow-clockwise {
  animation: spin 1s linear infinite;
}

/* Ensure collapse backdrop is behind navbar components */
.modal-backdrop {
  z-index: 0;
}

/* Center all navbar items vertically */
.navbar .navbar-nav .nav-item {
  display: flex;
  align-items: center;
}

/* Ensure the container uses flexbox and centers items vertically */
.navbar .container-fluid {
  display: flex;
  align-items: center;
}

/* Example: Adjusting for small screens */
@media (max-width: 992px) { /* lg breakpoint */
  .navbar .btn-light {
    margin-top: 0; /* Reset any top margin */
    margin-bottom: 0; /* Reset any bottom margin */
  }
}


