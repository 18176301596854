.btn-group-vertical .btn {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  .btn-group-vertical .btn {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }


  .custom-badge {
    /* background-color: #f1f1f1; */
    /* Add any other desired styles for the badge */
    color: rgb(0, 0, 0);
  }

  /*  smaller padding for the buttons top and bottom, they became a bit more sleek */
  .btn-smaller {
    padding-top: 1%;
    padding-bottom: 1%;
  }


.btn {
  font-size: 1.8rem;
}
  /*  make background of bnt-ligth slightly offwhite */
  button.btn.btn-smaller.btn-light.text-start {
    background-color: rgb(251, 251, 251)
}


/* // flashing dots css */

.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #CF7917;
  color: #CF7917;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
  top: 15px;;
}
.dot-flashing::before, .dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #CF7917;
  color: #CF7917;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}
.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #CF7917;
  color: #CF7917;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: #CF7917;
  }
  50%, 100% {
    background-color: rgba(234, 232, 237, 0.2);
  }
}