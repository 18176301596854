@keyframes pulse2 {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.3);
      opacity: 0.4; 
      color: red;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  .blink-icon2 {
    color: white;
    animation: pulse2 0.9s infinite;
}
  

#tooltip2 {
  background: #fbf3ea;
  color: black;
  font-weight: bold;
  padding: 4px 10px;
  font-size: 18px;
  border-radius: 4px;
  
}

.tooltipHide{
  display: none
}

.tooltipShow {
  display: block
}

#tooltip2[data-popper-placement^='top'] > #arrow {
  bottom: -4px;
}

#arrow,
#arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
}

#arrow {
  visibility: hidden;
}

#arrow::before {
  visibility: visible;
  content: '';
  transform: rotate(45deg);
}

.text-orange {
    color: #CF7917 !important; /* Orange color */
}

.custom-mic2-btn .bi {
    color: #CF7917 !important; /* Orange color */
    font-size: 2.8rem;
    display: flex;
    align-content: center;
    justify-content: center;
    padding: 1rem;

}

