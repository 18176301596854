@keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.3);
      opacity: 0.4; 
      color: rgb(252, 0, 0);
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  .blink-icon1 {
    color: rgb(255, 255, 255);
    animation: pulse 0.9s infinite;
    font-size: 2.8rem;
    display: flex;
    align-content: center;
    justify-content: center;

}
  

#tooltip {
  background: #CF7917;
  color: rgb(255, 255, 255);
  font-weight: bold;
  padding: 4px 10px;
  font-size: 18px;
  border-radius: 4px;
  
}

.tooltipHide{
  display: none
}

.tooltipShow {
  display: block
}

#tooltip[data-popper-placement^='top'] > #arrow {
  bottom: -4px;
}

#arrow,
#arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
}

#arrow {
  visibility: hidden;
}

#arrow::before {
  visibility: visible;
  content: '';
  transform: rotate(45deg);
}

.custom-mic1-btn .bi {
    color: #FFFFFF !important; /* White color */
    font-size: 2.8rem;
    display: flex;
    align-content: center;
    justify-content: center;
    padding: 1rem;
}


.custom-spinner {
  font-size: 1.67rem;
  display: flex;
  align-content: center;
  justify-content: center;
  padding: 2rem;
}

#micpopper {
  padding: 1rem
}
@media only screen and (min-width: 768px) {
  #micpopper {
  padding: 3rem
  }
}